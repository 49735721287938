import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Icon, Select, Tabs } from 'antd';
import { get, put, post } from '../../../utils';
import { getProvince, getDistrict, getSubDistrict } from '../../../utils/fetchData';
import './style.scss'

const { TabPane } = Tabs;

const FormItem = Form.Item;
const { Meta } = Card;
const Option = Select.Option;

class OrderAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userCustomerAddressList: [],
            provinceList: [],
            districtList: [],
            subdistrictList: [],

            invoiceProvinceList: [],
            invoiceDistrictList: [],
            invoiceSubdistrictList: [],

            provinceId: null,
            districtId: null,
            subdistrictId: null,

            invoiceProvinceId: null,
            invoiceDistrictId: null,
            invoiceSubdistrictId: null,


            tempDeliveryName: null,
            tempDeliveryTelephone: null,
            tempDeliveryAddress: null,
            tempDeliveryProvince: null,
            tempDeliveryDistrict: null,
            tempDeliverySubDistrict: null,
            tempDeliveryPostCode: null,

            tempInvoiceName: null,
            tempInvoiceTelephone: null,
            tempInvoiceAddress: null,
            tempInvoiceProvince: null,
            tempInvoiceDistrict: null,
            tempInvoiceSubDistrict: null,
            tempInvoicePostCode: null,

            isEditDelivery: false,
            isCreateDelivery: false,
            isEditInvoice: false,
            isCreateInvoice: false,
        }
    }

    getUserCustomerAddressList(callback = () => null) {
        const { userCustomerId } = this.props
        let url = '/api/address?user_customer_id=' + userCustomerId;
        get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ userCustomerAddressList: responses }, () => callback())
            })
            .catch(err => {
                console.log(err.message)
                callback()
            })
    }

    componentDidMount() {
        if (this.props.userCustomerId) {
            this.getUserCustomerAddressList()
        }
        this.getInitData()
    }

    async getInitData(){
        try {
           const provinceData = await getProvince()
           this.setState({ provinceList: provinceData, invoiceProvinceList: provinceData  })
        } catch (e) {
            console.log(e)
        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userCustomerId !== prevProps.userCustomerId && this.props.userCustomerId) {
            this.getUserCustomerAddressList()
        }
        if (this.props.deliveryAddressId !== prevProps.deliveryAddressId) {
            this.setState({ deliveryAddressId: this.props.deliveryAddressId })
            if(this.props.deliveryAddressId){
                this.getAddressOption({...this.props.deliveryAddressDetail, type: "delivery"})
            }
            
        }
        if (this.props.invoiceAddressId !== prevProps.invoiceAddressId) {
            this.setState({ invoiceAddressId: this.props.invoiceAddressId })
            if(this.props.invoiceAddressId){
                this.getAddressOption({...this.props.invoiceAddressDetail, type: "invoice"})
            }
        }
    }

    onChangeDeliveryAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id == value)
        if (index !== -1) {
            this.props.updateDeliveryAddressId(userCustomerAddressList[index].id)
            this.props.updateDeliveryAddressDetail(userCustomerAddressList[index])
            this.getAddressOption({...userCustomerAddressList[index], type: "delivery"})
        }else{
            this.props.updateDeliveryAddressId(null)
            this.props.updateDeliveryAddressDetail(null)
        }
    }
    onChangeInvoiceAddressDetail(value) {
        const { userCustomerAddressList } = this.state
        let index = userCustomerAddressList.findIndex(item => item.id == value)
        if (index !== -1) {
            this.props.updateInvoiceAddressId(userCustomerAddressList[index].id)
            this.props.updateInvoiceAddressDetail(userCustomerAddressList[index])
            this.getAddressOption({...userCustomerAddressList[index], type: "invoice"})
        }else{
            this.props.updateInvoiceAddressId(null)
            this.props.updateInvoiceAddressDetail(null)
        }
    }

    async getAddressOption({province_id, district_id, type="delivery"}) {
        try {
            const districtData = await getDistrict(province_id)
            const subDistrictData = await getSubDistrict(district_id)
            if(type==="delivery"){
                this.setState({ 
                    districtList: districtData,
                    subdistrictList: subDistrictData 
                })
                return
            }else if(type==="invoice"){
                this.setState({ 
                    invoiceDistrictList: districtData,
                    invoiceSubdistrictList: subDistrictData 
                })
            }
            
        } catch (error) {
            console.log('error :>> ', error);
        }
        
    }

    onClickEditDelivery() {
        const { deliveryAddressDetail } = this.props
        if (deliveryAddressDetail) {
            this.setState({
                isEditDelivery: true,

                tempDeliveryName: deliveryAddressDetail.name,
                tempDeliveryTelephone: deliveryAddressDetail.telephone,
                tempDeliveryAddress: deliveryAddressDetail.address,
                tempDeliveryProvince: deliveryAddressDetail.province,
                tempDeliveryDistrict: deliveryAddressDetail.district,
                tempDeliverySubDistrict: deliveryAddressDetail.sub_district,
                tempDeliveryPostCode: deliveryAddressDetail.post_code,
                
                provinceId:  deliveryAddressDetail.province_id,
                districtId: deliveryAddressDetail.district_id,
                subdistrictId: deliveryAddressDetail.sub_district_id,
            })
        }

    }

    onClickCreateDelivery() {
        this.setState({ isCreateDelivery: true })
    }

    onClickDoneDelivery() {
        const { userCustomerId } = this.props
        const { deliveryAddressId, tempDeliveryName, tempDeliveryTelephone, tempDeliveryAddress, tempDeliveryProvince, tempDeliveryDistrict, tempDeliverySubDistrict, tempDeliveryPostCode,
            provinceId, districtId, subdistrictId

        } = this.state
        
        let data = {
            name: tempDeliveryName,
            telephone: tempDeliveryTelephone,
            address: tempDeliveryAddress,
            province: tempDeliveryProvince,
            district: tempDeliveryDistrict,
            sub_district: tempDeliverySubDistrict,
            post_code: tempDeliveryPostCode,
            province_id: provinceId,
            district_id: districtId,
            sub_district_id: subdistrictId,
        }
        let url = '/api/crm/administrator/address';
        let update = post
        if (deliveryAddressId) {
            url = '/api/crm/administrator/address/' + deliveryAddressId;
            update = put
        } else {
            data['user_customer_id'] = userCustomerId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getUserCustomerAddressList(() => {
                    this.onChangeDeliveryAddressDetail(response.id)
                    this.clearStateDelivery()
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickCancelDelivery() {
        this.clearStateDelivery()
    }

    clearStateDelivery() {
        this.setState({
            isEditDelivery: false,
            isCreateDelivery: false,

            tempDeliveryName: null,
            tempDeliveryTelephone: null,
            tempDeliveryAddress: null,
            tempDeliveryProvince: null,
            tempDeliveryDistrict: null,
            tempDeliverySubDistrict: null,
            tempDeliveryPostCode: null,
            provinceId:  null,
            districtId: null,
            subdistrictId: null,
        })
    }


    // Invoice
    onClickEditInvoice() {
        const { invoiceAddressDetail } = this.props
        if (invoiceAddressDetail) {
            this.setState({
                isEditInvoice: true,

                tempInvoiceName: invoiceAddressDetail.name,
                tempInvoiceTelephone: invoiceAddressDetail.telephone,
                tempInvoiceAddress: invoiceAddressDetail.address,
                tempInvoiceProvince: invoiceAddressDetail.province,
                tempInvoiceDistrict: invoiceAddressDetail.district,
                tempInvoiceSubDistrict: invoiceAddressDetail.sub_district,
                tempInvoicePostCode: invoiceAddressDetail.post_code,

                invoiceProvinceId:  invoiceAddressDetail.province_id,
                invoiceDistrictId: invoiceAddressDetail.district_id,
                invoiceSubdistrictId: invoiceAddressDetail.sub_district_id,
            })
        }
    }

    onClickCreateInvoice() {
        this.setState({ isCreateInvoice: true })
    }

    onClickDoneInvoice() {
        const { userCustomerId } = this.props
        const { invoiceAddressId, tempInvoiceName, tempInvoiceTelephone, tempInvoiceAddress, tempInvoiceProvince, tempInvoiceDistrict, tempInvoiceSubDistrict, tempInvoicePostCode,
            invoiceProvinceId, invoiceDistrictId, invoiceSubdistrictId, 
        } = this.state
        let data = {
            name: tempInvoiceName,
            telephone: tempInvoiceTelephone,
            address: tempInvoiceAddress,
            province: tempInvoiceProvince,
            district: tempInvoiceDistrict,
            sub_district: tempInvoiceSubDistrict,
            post_code: tempInvoicePostCode,

            province_id: invoiceProvinceId,
            district_id: invoiceDistrictId,
            sub_district_id: invoiceSubdistrictId,

        }
        let url = '/api/crm/administrator/address';
        let update = post
        if (invoiceAddressId) {
            url = '/api/crm/administrator/address/' + invoiceAddressId;
            update = put
        } else {
            data['user_customer_id'] = userCustomerId
        }
        update(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.getUserCustomerAddressList(() => {
                    this.onChangeInvoiceAddressDetail(response.id)
                    this.clearStateInvoice()
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    onClickCancelInvoice() {
        this.clearStateInvoice()
    }

    clearStateInvoice() {
        this.setState({
            isEditInvoice: false,
            isCreateInvoice: false,

            tempInvoiceName: null,
            tempInvoiceTelephone: null,
            tempInvoiceAddress: null,
            tempInvoiceProvince: null,
            tempInvoiceDistrict: null,
            tempInvoiceSubDistrict: null,
            tempInvoicePostCode: null,

            invoiceProvinceId:  null,
            invoiceDistrictId: null,
            invoiceSubdistrictId: null,
        })
    }

    async onChangeProvince(value){
        try {
            const { provinceList } = this.state
            const provinceData = provinceList.find( i => i.id == value)
            if(provinceData){
                this.setState({
                    provinceId: value,
                    tempDeliveryProvince: provinceData.name_th,
                    districtId :null,
                    tempDeliveryDistrict: null,
                    subdistrictId:null,
                    tempDeliverySubDistrict: null,
                })
                
                const districtData = await getDistrict(value)
                this.setState({ districtList: districtData })
            }

            return
        } catch (error) {
            console.log(error)
        }   
    }

    async onChangeDistrict(value){
        try {
            const { districtList } = this.state
            const districtData = districtList.find( i => i.id == value) 
            if(districtData){
                this.setState({
                    districtId: value,
                    tempDeliveryDistrict: districtData.name_th,
                    subdistrictId:null,
                    tempDeliverySubDistrict: null,
                })
                
                const subDistrictData = await getSubDistrict(value)
                this.setState({ subdistrictList: subDistrictData })
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    async onChangeSubDistrict(value){
        try {
            const {subdistrictList} = this.state
            const subDistrictData = subdistrictList.find(i=> i.id === value)
            if(subDistrictData){
                this.setState({
                    subdistrictId: value,
                    tempDeliveryPostCode: subDistrictData.zipcode
                })
            }
            
            
        } catch (error) {
            console.log(error)
        }
        
    }


    async onChangeInvoiceProvince(value){
        try {
            const { invoiceProvinceList } = this.state
            const provinceData = invoiceProvinceList.find( i => i.id == value)
            if(provinceData){
                this.setState({
                    invoiceProvinceId: value,
                    tempInvoiceProvince: provinceData.name_th,
                    invoiceDistrictId :null,
                    tempInvoiceDistrict: null,
                    invoiceSubdistrictId:null,
                    tempInvoiceSubDistrict: null,
                })
                
                const districtData = await getDistrict(value)
                this.setState({ invoiceDistrictList: districtData })
            }

            return
        } catch (error) {
            console.log(error)
        }   
    }

    async onChangeInvoiceDistrict(value){
        try {
            const { invoiceDistrictList } = this.state
            const districtData = invoiceDistrictList.find( i => i.id == value) 
            if(districtData){

                this.setState({
                    invoiceDistrictId: value,
                    tempInvoiceDistrict: districtData.name_th,
                    invoiceSubdistrictId:null,
                    tempInvoiceSubDistrict: null,
                })
                
                const subDistrictData = await getSubDistrict(value)
                this.setState({ invoiceSubdistrictList: subDistrictData })
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    async onChangeInvoiceSubDistrict(value){
        try {
            const { invoiceSubdistrictList } = this.state
            const subDistrictData = invoiceSubdistrictList.find(i=> i.id === value)
            if(subDistrictData){
                this.setState({
                    invoiceSubdistrictId: value,
                    tempInvoicePostCode: subDistrictData.zipcode
                })
            }
            
            
        } catch (error) {
            console.log(error)
        }
        
    }


    userCustomerAddressListToEnum(){
        const {userCustomerAddressList} = this.state
        let result = userCustomerAddressList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)
        result.unshift(<Option key={99999} value={null}>{'--- ไม่ระบุ ---'}</Option>)
        return result
    }

    provinceListToElem(provinceList) {
        let result = provinceList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }

    districtListToElem(districtList) {
        let result = districtList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }

    subdistrictListToElem(subdistrictList) {
        let result = subdistrictList.map(i => {
            return <Option key={i.id} value={i.id} title={`${i.name_th}`}>{i.name_th}</Option>
        })
        return result
    }
    
    render() {
        const { userCustomerId, deliveryAddressId, invoiceAddressId, deliveryAddressDetail, invoiceAddressDetail } = this.props
        const {
            isEditDelivery, isCreateDelivery, isEditInvoice, isCreateInvoice,
            tempDeliveryName, tempDeliveryTelephone, tempDeliveryAddress, tempDeliveryProvince, tempDeliveryDistrict, tempDeliveryPostCode,
            tempInvoiceName, tempInvoiceTelephone, tempInvoiceAddress, tempInvoiceProvince, tempInvoiceDistrict, tempInvoicePostCode,
            provinceList, districtList, subdistrictList, invoiceProvinceList, invoiceDistrictList, invoiceSubdistrictList, 
            provinceId, districtId, subdistrictId,
            invoiceProvinceId, invoiceDistrictId, invoiceSubdistrictId
        } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='order-address-container-wrapper'>
                <Tabs onChange={() => null} type="card">
                    <TabPane tab={"Shipping Address"} key={"1"}>
                        {userCustomerId ?
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={10} xl={10} >
                                        <Meta title={'Shipping Address'} />
                                    </Col>
                                    <Col sm={24} md={24} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {isCreateDelivery || isEditDelivery ?
                                            <Fragment>
                                                <Button type={'primary'} style={{ width: '48%' }} onClick={() => this.onClickDoneDelivery()}>{'Done'}</Button>
                                                <Button type={'danger'} style={{ width: '48%' }} onClick={() => this.onClickCancelDelivery()}>{'Cancel'}</Button>
                                            </Fragment>
                                            :
                                            deliveryAddressId ?
                                                <Button type={isEditDelivery ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickEditDelivery()}>{'Edit'}</Button>
                                                :
                                                <Button type={isCreateDelivery ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickCreateDelivery()}>{'Create'}</Button>
                                        }
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <Select
                                            value={deliveryAddressId}
                                            onChange={(value) => this.onChangeDeliveryAddressDetail(value)}
                                            disabled={isCreateDelivery || isEditDelivery}
                                        >
                                            {this.userCustomerAddressListToEnum() }
                                        </Select>
                                    </Col>
                                </Row>
                                <Form>
                                    <Row gutter={24}>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Name'} required={false}>
                                                <Input
                                                    value={tempDeliveryName ? tempDeliveryName : deliveryAddressDetail ? deliveryAddressDetail.name : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryName: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'telephone'} required={false}>
                                                <Input
                                                    value={tempDeliveryTelephone ? tempDeliveryTelephone : deliveryAddressDetail ? deliveryAddressDetail.telephone : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryTelephone: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Address'} required={true}>
                                                <Input
                                                    value={tempDeliveryAddress ? tempDeliveryAddress : deliveryAddressDetail ? deliveryAddressDetail.address : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryAddress: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Province'} required={true}>
                                                <Select
                                                    id='Province'
                                                    showSearch
                                                    optionFilterProp={'title'}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    // value={provinceId}
                                                    value={isEditDelivery || isCreateDelivery ? provinceId : deliveryAddressDetail ? deliveryAddressDetail.province_id : ''}
                                                    
                                                    onChange={(value) => this.onChangeProvince(value)}
                                                >
                                                    {this.provinceListToElem(provinceList)}
                                                </Select>
                                                {/* <Input
                                                    value={tempDeliveryProvince ? tempDeliveryProvince : deliveryAddressDetail ? deliveryAddressDetail.province : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryProvince: event.target.value })}
                                                /> */}
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'District'} required={true}>
                                                <Select
                                                    id='District'
                                                    showSearch
                                                    optionFilterProp={'title'}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    // value={districtId}
                                                    value={isEditDelivery || isCreateDelivery ? districtId : deliveryAddressDetail ? deliveryAddressDetail.district_id : ''}
                                                    
                                                    onChange={(value) => this.onChangeDistrict(value)}
                                                >
                                                    {this.districtListToElem(districtList)}
                                                </Select>
                                                {/* <Input
                                                    value={tempDeliveryDistrict ? tempDeliveryDistrict : deliveryAddressDetail ? deliveryAddressDetail.district : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryDistrict: event.target.value })}
                                                /> */}
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Sub District'} required={true}>
                                                <Select
                                                    id='Subdistrict'
                                                    showSearch
                                                    optionFilterProp={'title'}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    // value={subdistrictId}
                                                    value={isEditDelivery || isCreateDelivery ? subdistrictId : deliveryAddressDetail ? deliveryAddressDetail.sub_district_id : ''}
                                                    
                                                    onChange={(value) => this.onChangeSubDistrict(value)}
                                                    // disabled={this.props.type == 'create' ? this.state.isdisabled : this.props.editRole ? this.state.isdisabled : true}
                                                    // onSearch={(value)=>this.onSearchCustomer(value)}
                                                    // notFoundContent={this.state.loadingCustomer ? <Spin size="small" /> : null}
                                                >
                                                    {this.subdistrictListToElem(subdistrictList)}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Post Code'} required={true}>
                                                <Input
                                                    value={tempDeliveryPostCode ? tempDeliveryPostCode : deliveryAddressDetail ? deliveryAddressDetail.post_code : ''}
                                                    disabled={!isEditDelivery && !isCreateDelivery}
                                                    onChange={(event) => this.setState({ tempDeliveryPostCode: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            :
                            <div style={{ height: 300, textAlign: 'center', padding: 125 }}>
                                <h2>{'Please select customer'}</h2>
                            </div>
                        }
                    </TabPane>
                    <TabPane tab={'Invoice Address'} key={"2"}>
                        {userCustomerId ?
                            <Card className='cardstyle'>
                                <Row gutter={24}>
                                    <Col sm={24} md={24} lg={10} xl={10} >
                                        <Meta title={'Invoice Address'} />
                                    </Col>
                                    <Col sm={24} md={24} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {isCreateInvoice || isEditInvoice ?
                                            <Fragment>
                                                <Button type={'primary'} style={{ width: '48%' }} onClick={() => this.onClickDoneInvoice()}>{'Done'}</Button>
                                                <Button type={'danger'} style={{ width: '48%' }} onClick={() => this.onClickCancelInvoice()}>{'Cancel'}</Button>
                                            </Fragment>
                                            :
                                            invoiceAddressId ?
                                                <Button type={isEditInvoice ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickEditInvoice()}>{'Edit'}</Button>
                                                :
                                                <Button type={isCreateInvoice ? 'primary' : 'default'} style={{ width: '100%' }} onClick={() => this.onClickCreateInvoice()}>{'Create'}</Button>
                                        }
                                    </Col>
                                    <Col sm={24} md={24} lg={8} xl={8} >
                                        <Select
                                            value={invoiceAddressId}
                                            onChange={(value) => this.onChangeInvoiceAddressDetail(value)}
                                            disabled={isCreateInvoice || isEditInvoice}
                                        >
                                            {this.userCustomerAddressListToEnum()}
                                        </Select>
                                    </Col>
                                </Row>
                                <Form>
                                    <Row gutter={24}>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Name'} required={false}>
                                                <Input
                                                    value={tempInvoiceName ? tempInvoiceName : invoiceAddressDetail ? invoiceAddressDetail.name : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceName: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'telephone'} required={false}>
                                                <Input
                                                    value={tempInvoiceTelephone ? tempInvoiceTelephone : invoiceAddressDetail ? invoiceAddressDetail.telephone : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceTelephone: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Address'} required={true}>
                                                <Input
                                                    value={tempInvoiceAddress ? tempInvoiceAddress : invoiceAddressDetail ? invoiceAddressDetail.address : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceAddress: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Province'} required={true}>
                                                <Select
                                                    id='Province'
                                                    showSearch
                                                    optionFilterProp={'title'}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    // value={provinceId}
                                                    value={isEditInvoice || isCreateInvoice ? invoiceProvinceId : invoiceAddressDetail ? invoiceAddressDetail.province_id : ''}
                                                    
                                                    onChange={(value) => this.onChangeInvoiceProvince(value)}
                                                >
                                                    {this.provinceListToElem(invoiceProvinceList)}
                                                </Select>
                                                {/* <Input
                                                    value={tempInvoiceProvince ? tempInvoiceProvince : invoiceAddressDetail ? invoiceAddressDetail.province : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceProvince: event.target.value })}
                                                /> */}
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'District'} required={true}>
                                                <Select
                                                    id='District'
                                                    showSearch
                                                    optionFilterProp={'title'}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    // value={districtId}
                                                    value={isEditInvoice || isCreateInvoice ? invoiceDistrictId : invoiceAddressDetail ? invoiceAddressDetail.district_id : ''}
                                                    
                                                    onChange={(value) => this.onChangeInvoiceDistrict(value)}
                                                >
                                                    {this.districtListToElem(invoiceDistrictList)}
                                                </Select>
                                                {/* <Input
                                                    value={tempInvoiceDistrict ? tempInvoiceDistrict : invoiceAddressDetail ? invoiceAddressDetail.district : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoiceDistrict: event.target.value })}
                                                /> */}
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Sub District'} required={true}>
                                                <Select
                                                    id='Subdistrict'
                                                    showSearch
                                                    optionFilterProp={'title'}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    // value={subdistrictId}
                                                    value={isEditInvoice || isCreateInvoice ? invoiceSubdistrictId : invoiceAddressDetail ? invoiceAddressDetail.sub_district_id : ''}
                                                    
                                                    onChange={(value) => this.onChangeInvoiceSubDistrict(value)}
                                                    // disabled={this.props.type == 'create' ? this.state.isdisabled : this.props.editRole ? this.state.isdisabled : true}
                                                    // onSearch={(value)=>this.onSearchCustomer(value)}
                                                    // notFoundContent={this.state.loadingCustomer ? <Spin size="small" /> : null}
                                                >
                                                    {this.subdistrictListToElem(invoiceSubdistrictList)}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col sm={24} md={24} lg={8} xl={8} >
                                            <FormItem {...formItemLayout} label={'Post Code'} required={true}>
                                                <Input
                                                    value={tempInvoicePostCode ? tempInvoicePostCode : invoiceAddressDetail ? invoiceAddressDetail.post_code : ''}
                                                    disabled={!isEditInvoice && !isCreateInvoice}
                                                    onChange={(event) => this.setState({ tempInvoicePostCode: event.target.value })}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            :
                            <div style={{ height: 300, textAlign: 'center', padding: 125 }}>
                                <h2>{'Please select customer'}</h2>
                            </div>
                        }
                    </TabPane>
                </Tabs>


            </div>
        );
    }
}



export default OrderAddress;

