import fuzzysearch from 'fuzzysearch';
import moment from 'moment'

const getUnique = (p, c) => {
    if (p.includes(c)) return p;
    return p.concat(c);
}

function filterColumn(row, key, state) {
    const { filters } = state;
    let type = state.bodyFilters.filters[key];
    if (!type) {
        type = 'contain'
    }
    if (key === 'id') return true
    if (!filters[key]) return true
    let data = row[key]
    let isNumber = (typeof row[key]) === 'number'
    if ((typeof row[key]) === 'number') {
        data += ''
    }
    if ((typeof row[key]) === 'object') {
        data = ''
    }
    if (['equal', 'not equal', 'less than', 'greater than', 'less than or', 'greater than or'].includes(type) && key == "Wage") {
        data = parseInt(data.split(' ')[0])
        isNumber = true
    }
    if(data && ['Create Datetime', 'Modify Datetime'].includes(key)){
        data = moment(data).format('YYYY-MM-DD')
    }
    switch (type) {
        case 'contain': {
            if (['Status'].includes(key)) {
                return data == (filters[key] || '')
            } else if (['Create Datetime', 'Modify Datetime'].includes(key)) {
                return (moment(filters[key], 'YYYY-MM-DD').toString() || '') == moment(data, 'YYYY-MM-DD').toString()
            } else {
                return fuzzysearch((filters[key] || '').toString().toLowerCase(), (data).toString().toLowerCase())
            }
        }
        case 'equal': {
            if (isNumber) return data == Number(filters[key] || 0)
            return data.toLowerCase() == (filters[key]).toString().toLowerCase()
        }
        case 'not equal': {
            if (isNumber) return data != Number(filters[key] || 0)
            return data.toLowerCase() != (filters[key]).toString().toLowerCase()
        }
        case 'less than': {
            if (isNumber) return data < Number(filters[key] || 0)
            return data.toLowerCase() < (filters[key]).toString().toLowerCase()
        }
        case 'greater than': {
            if (isNumber) return data > Number(filters[key] || 0)
            return data.toLowerCase() > (filters[key]).toString().toLowerCase()
        }
        case 'less than or': {
            if (isNumber) return data <= Number(filters[key] || 0)
            return data.toLowerCase() <= (filters[key]).toString().toLowerCase()
        }
        case 'greater than or': {
            if (isNumber) return data >= Number(filters[key] || 0)
            return data.toLowerCase() >= (filters[key]).toString().toLowerCase()
        }
        default: return true
    }
}
function filterRow(row, state) {
    const result = Object.keys(row).map(key => filterColumn(row, key, state))
    return result.every(r => r === true);
}
function sorting(a, b, state) {
    const { sorting } = state.bodyFilters;
    if (sorting.key == '') {
        return true;
    }
    const { key, sort } = sorting;
    if ((typeof a[key]) === 'number') {
        if (sort === 'asc') {
            return Number(a[key] || 0) - Number(b[key] || 0);
        } else {
            return Number(b[key] || 0) - Number(a[key] || 0);
        }
    }
    if (sort === 'asc') {
        return (a[key] || '').toString().localeCompare((b[key] || '').toString());
    } else {
        return (b[key] || '').toString().localeCompare((a[key] || '').toString());
    }
}

function filterDisplay(state) {
    const { limit, page, rows, bodyFilters } = state
    const filteredRows = rows.filter(row => filterRow(row, state))
        .map(row => ({ ...row, key: row.id }))
    let sorted = filteredRows
    // console.log('stateeeee2',sorted)
    if (bodyFilters.sorting.key !== '') {
        sorted = filteredRows.sort((a, b) => sorting(a, b, state));
    }
    // const total = Math.ceil(filteredRows.length / limit)
    const total = sorted.length
    let nextPage = page
    if (page > total) {
        nextPage = total
        if (total === 0) {
            nextPage = 1
        }
    }
    return {
        display: sorted.slice(limit * (nextPage - 1), limit * nextPage),
        filtered: sorted,
        page: nextPage,
        total,
    }
}



export function setContentCustomTable(state, payload) {
    const { rows , page } = payload
    const { limit } = state
    let fileterOrderlist = rows.slice((limit * (page - 1)), limit * page)
    return {
        ...state,
        rows: rows,
        filtered: rows.map(r => ({ ...r, key: r.id })),
        // total: Math.ceil(rows.length / limit),
        total: rows.length,
        // display: rows.slice(0, limit),
        display: fileterOrderlist,
        columns: rows.length > 0 ? Object.keys(rows[0]) : [],
        page : page
    }
}

export function filteringCustomTable(state, payload) {
    const { filters } = payload
    const nextState = {
        ...state,
        filters: Object.assign(state.filters, filters),
    }
    return {
        ...state,
        ...filterDisplay(nextState)
    }
    // const nextState = {
    //     ...state,
    //     filters: Object.assign(state.filters, action.payload),
    // }
    // const archive = Object.assign(state.archive, {
    //     [state.category]: {
    //         ...nextState,
    //         ...filterDisplay(nextState),
    //     }
    // })
    // return {
    //     ...state,
    //     ...filterDisplay(nextState),
    //     archive,
    // }
}


export function sortingCustomTable(state, payload) {
    const { column, value } = payload

    let nextState = {}
    if (value.key === 'clear') {
        nextState = {
            ...state,
            // category,
            bodyFilters: {
                sorting: {
                    key: '',
                    sort: 'asc',
                },
            },
        }
    }
    if (value.keyPath.includes('filter')) {
        const { filters } = state.bodyFilters
        if (filters[column] === value.key) {
            const newFilters = Object.keys(filters).filter(key => key !== column).reduce((p, c) => Object.assign(p, c), {})
            nextState = {
                ...state,
                // category,
                bodyFilters: Object.assign(state.bodyFilters, { filters: newFilters })
            }
        } else {
            nextState = {
                ...state,
                // category,
                bodyFilters: Object.assign(state.bodyFilters, { filters: { ...state.bodyFilters.filters, [column]: value.key } })
            }
        }
    } else if (value.keyPath.includes('columns')) {
        const { columns } = state.bodyFilters;
        if (columns.includes(value.key)) {
            nextState = {
                ...state,
                // category,
                bodyFilters: Object.assign(state.bodyFilters, { columns: columns.filter(c => c !== value.key) })
            }
        } else {
            nextState = {
                ...state,
                // category,
                bodyFilters: Object.assign(state.bodyFilters, { columns: columns.concat(value.key).reduce(getUnique, []) })
            }
        }
    } else {
        nextState = {
            ...state,
            // category,
            bodyFilters: Object.assign(state.bodyFilters, { sorting: { key: column, sort: value.key } })
        }
    }
    const next = Object.assign({}, nextState)
    return {
        ...next,
        ...filterDisplay(next)
    }

    // delete next.archive
    // const archive = Object.assign(state.archive, {
    //     [category]: {
    //         ...next,
    //         // category,
    //         ...filterDisplay(next),
    //     }
    // })
    // return {
    //     ...archive[category],
    //     archive,
    // }
}


