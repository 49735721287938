import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Card, Row, Col, Button, Select, Icon, notification, Switch, Table, Modal, Popconfirm, Tooltip } from 'antd';
import { get, post, ppost, put } from '../../../utils';
import { deleteFileInServer } from '../../../utils/fetchData';
import ImageUpload from '../../../containers/ImageUpload';
import LoadingModal from '../../../containers/CustomModal/LoadingModal';
// import UploadImageModal from '../../../containers/CustomModal/UploadImageModal';
import NotificationManualModal from '../../Notification/NotificationManualSend/NotificationManualModal'
import defaultSquareSrc from '../../../resource/default/300x300-square.png'
import ProductSkuModal from '../ProductSkuModal';
import VideoUpload from '../../../containers/VideoUpload';
import './style.scss'

notification.config({
    placement: 'topRight',
    duration: 1,
});
const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};
const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            uploading: false,
            visible: false,
            name: '',
            categoryProductId: null,
            categoryTicketId: null,
            detail: '',
            weight: 0,
            total: 0,
            purchase: 0,
            inventory: 0,
            price: 0,
            priceForSale: 0,
            detailList: [],
            descriptionList: [],
            imageCoverList: [],
            imageDetailList: [],
            videoDetailList: [],

            languageList: [],
            categoryProductList: [],
            categoryTicketList: [],
            visibleModalAddProductSku: false,
            isNotUsePriceForAllProduct: false,

            isUsePointIndividuallySku: false,
            coursePoint: 0,

            productId: null,
            productSku: [],
            productSkuCurrentItem: false,
            productTitleList: [],
            productTitleId: 0,
            deleteFileList: []
        }
    }

    setStatusLoading(status) {
        this.setState({ loading: status })
    }

    getLanguageList() {
        let url = '/api/crm/administrator/language/raw';
        return get(url)
            .then(responses => {
                if (responses.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ languageList: responses })
                let detailList = []

                responses.map(item => {
                    detailList.push({ name: '', language_id: item.id })
                })

                this.setState({ languageList: responses, detailList: detailList })
                return responses
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCategoryProductList() {
        let url = '/api/category/product/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ categoryProductList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getCategoryTicketList() {
        let url = '/api/crm/administrator/category/ticket/';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                this.setState({ categoryTicketList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    getProductTitleList() {
        let url = '/api/administrator/product-title';
        get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const newItem = { id: 0, name: '--- None ---' };
                response.unshift(newItem);
                this.setState({ productTitleList: response })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getProductDetail(id) {
        const { folderName } = this.props
        const { languageList } = this.state
        let url = '/api/administrator/product/' + id;
        return get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                let imageCoverList = []
                if (response.image) {
                    imageCoverList = [{
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${response.image}`,
                        file: null,
                        name: response.image
                    }]
                }
                let imageDetailList = response.imageDetailList.map(item => {
                    return {
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`,
                        file: null,
                        name: item.name
                    }
                })

                const videoDetailList = response.videoDetailList.map(item => {
                    return {
                        view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.name}`,
                        file: null,
                        name: item.name
                    }
                })

                languageList.map(item => {
                    let index = response.detailList.findIndex(itemData => itemData.language_id == item.id)
                    if (index == -1) {
                        response.detailList.push({ name: '', language_id: item.id })
                    }
                })

                this.setState({
                    productId: response.id,
                    name: response.name,
                    categoryProductId: response.category_product_id,
                    categoryTicketId: response.category_ticket_id,
                    detail: response.detail,
                    weight: response.weight,
                    total: response.total,
                    purchase: response.purchase,
                    inventory: response.inventory,
                    price: response.price,
                    priceForSale: response.price_for_sale,
                    detailList: response.detailList,
                    descriptionList: response.descriptionList,
                    imageCoverList: imageCoverList,
                    imageDetailList: imageDetailList,
                    isNotUsePriceForAllProduct: response.is_not_use_price_for_all_product,
                    productTitleId: response.product_title_attr_id ? response.product_title_attr_id : 0,
                    videoDetailList,

                    isUsePointIndividuallySku: response.is_use_point_individually_sku,
                    coursePoint: response.product_point,
                })
                return response
            })
            .catch(err => {
                console.log(err.message)
            })
    }
    getProductSku(id) {
        const { folderName } = this.props
        const { languageList } = this.state
        let url = '/api/administrator/product-sku/' + id;
        return get(url)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                const updatedResponse = response.map(item => ({
                    ...item,
                    image:
                        [{
                            view: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOSTNAME}:${process.env.REACT_APP_API_PORT}/uploads/${folderName}/${item.image}`,
                            file: null,
                            name: item.image
                        }]
                }));
                this.setState({
                    productSku: updatedResponse
                })
                return response
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    async componentDidMount() {
        const { editId } = this.props
        await this.getLanguageList()
        this.getCategoryProductList()
        this.getCategoryTicketList()
        this.getProductTitleList()
        if (editId) {
            await this.getProductDetail(editId)
            await this.getProductSku(editId)
        }

    }

    updateProductData(data, type) {
        if (this.props.editId) {
            let url = '/api/administrator/crm/product/' + this.props.editId
            put(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.saveDeleteFileList()
                    this.setStatusLoading(false)
                    if (type === 'send') {
                        this.setState({ visible: true })
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    this.setStatusLoading(false)
                    console.log(err.message)
                })
        } else {
            let url = '/api/administrator/crm/product';
            post(url, data)
                .then(response => {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    this.setStatusLoading(false)
                    if (type === 'send') {
                        this.setState({ visible: true })
                        this.getProductDetail(response.productId)
                    } else {
                        this.props.backpage()
                    }
                })
                .catch(err => {
                    this.setStatusLoading(false)
                    console.log(err.message)
                })
        }
    }

    saveDeleteFileList() {
        let deleteFileList = [...this.state.deleteFileList]
        deleteFileList.forEach(deleteFile => {
            deleteFileInServer(deleteFile)
        })
        this.setState({ deleteFileList: [] })
        return
    }

    

    async uploadImageToServer(formData) {
        let url = '/api/crm/administrator/upload/file'
        return await ppost(url, formData)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response
            })
            .catch(err => {
                console.log(err.message)
                return []
            })
    }

    async updateProduct(type) {
        const { name, categoryProductId, categoryTicketId, detail, detailList, descriptionList, weight, total, purchase, priceForSale, inventory, price, imageCoverList, imageDetailList, productSku, isNotUsePriceForAllProduct,
            productTitleId,
            videoDetailList,
            coursePoint, isUsePointIndividuallySku
        } = this.state

        if (!name || name === '') {
            openNotificationWithIcon('warning', 'System notification', 'Please enter "Product Name".')
            return
        }
        if (!categoryProductId || categoryProductId === '') {
            openNotificationWithIcon('warning', 'System notification', 'Please select "Category Product".')
            return
        }
        // if (!categoryTicketId || categoryTicketId === '') {
        //     openNotificationWithIcon('warning', 'System notification', 'Please select "Category Chat".')
        //     return
        // }
        if(productSku.length <= 0){
            openNotificationWithIcon('warning', 'System notification', 'Please add "Product Sku".')
            return
        }

        this.setStatusLoading(true)

        const productSkuWithoutImage = productSku.map(({ image, ...rest }) => rest);
  
        let data = {
            name: name,
            category_product_id: categoryProductId,
            category_ticket_id: categoryTicketId,
            detail: detail,
            product_sku: JSON.stringify(productSkuWithoutImage),
            // weight: weight,
            // total: total,
            // purchase: purchase,
            // inventory: inventory,
            price: price,
            price_for_sale: priceForSale,
            image: null,
            detailList: JSON.stringify(detailList),
            descriptionList: JSON.stringify(descriptionList),
            imageDetailList: '[]',
            user: this.props.administratorId,
            is_not_use_price_for_all_product: isNotUsePriceForAllProduct,
            product_title_attr_id: productTitleId,
            videoDetailList: '[]',
            product_point: coursePoint,
            is_use_point_individually_sku: isUsePointIndividuallySku,
        }
        //upload รูป cover



        let uploadimageCoverList = imageCoverList.filter(item => item.file)
        let uploadDataimageCoverList = imageCoverList.filter(item => !item.file)
        if (uploadimageCoverList.length) {
            let formData = new FormData();
            uploadimageCoverList.map((item, index) => formData.append("product" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            if (fileNameList.length) {
                data.image = fileNameList[0]
            }
        } else {
            if (uploadDataimageCoverList.length) {
                data.image = imageCoverList[0].name
            }
        }

        //upload รูป detail
        let uploadimageDetailList = imageDetailList.filter(item => item.file)
        let uploadDataHomePageList = imageDetailList.filter(item => !item.file).map(item => item.name)
        if (uploadimageDetailList.length) {
            let formData = new FormData();
            uploadimageDetailList.map((item, index) => formData.append("product" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            fileNameList.map(fileName => {
                uploadDataHomePageList.push(fileName)
            })
        }
        data.imageDetailList = JSON.stringify(uploadDataHomePageList)

        //upload รูป productSku
        let productSkuWithImage = await Promise.all(productSku.map(async (product, index) => {
            if(typeof product.image === 'object'){
                let uploadImageProductSku = product.image.filter(item => item.file);
                let uploadDataProductSku = product.image.filter(item => !item.file).map(item => item.name);
                if (uploadImageProductSku.length) {
                    let formData = new FormData();
                    formData.append("product-sku" + index, uploadImageProductSku[0].file);
                    let fileNameList = await this.uploadImageToServer(formData);
                    if (Array.isArray(fileNameList) && fileNameList.length > 0) {
                        uploadDataProductSku.push(fileNameList[0]);
                    }
                }
                product.image = uploadDataProductSku[0];
            }

            //TODO ถ้า isNotUsePriceForAllProduct เป็น false product_sku ทุกอันต้องใช้ราคา product
            if (!isNotUsePriceForAllProduct) {
                product.price = price 
                product.price_for_sale = priceForSale
            }

            if(!isUsePointIndividuallySku){
                product.product_point = coursePoint
            }

            return product;
        }));

        //upload video detail
        let uploadVideoDetailList = videoDetailList.filter(item => item.file) //TODO: หา file ที่ต้อง upload
        let uploadDataVideoList = videoDetailList.filter(item => !item.file).map(item => item.name) //TODO: หา file ที่ไม่ต้อง upload
        if (uploadVideoDetailList.length) {
            let formData = new FormData();
            uploadVideoDetailList.forEach((item, index) => formData.append("video" + index, item.file))
            let fileNameList = await this.uploadImageToServer(formData)
            fileNameList.forEach(fileName => {
                uploadDataVideoList.push(fileName)
            })
        }
        data.videoDetailList = JSON.stringify(uploadDataVideoList)
        data.product_sku = JSON.stringify(productSkuWithImage);

        this.updateProductData(data, type)
    }

    onClickOk() {
        this.updateProduct('save')
    }
    onClickCancel() {
        this.setState({
            name: '',
            status: 'ยังไม่ส่ง',
            description: ''
        })
        this.props.backpage()
    }
    onClickSaveAndSend() {
        this.updateProduct('send')
    }

    onChangeNameDetailList(languageId, index, value) {
        let targetDetailList = this.state.detailList.filter(item => item.language_id === languageId)
        let otherDetailList = this.state.detailList.filter(item => item.language_id !== languageId)
        targetDetailList[index].name = value
        let detailList = [...otherDetailList, ...targetDetailList].sort((a, b) => a.language_id - b.language_id)
        this.setState({ detailList: detailList })
    }

    onDeleteDetailList(index) {
        let detailList = this.state.detailList.filter((item, i) => i != index)
        this.setState({ detailList: detailList })
    }

    onAddLanguageList() {
        let detailList = this.state.detailList.map(item => Object.assign({}, item))
        detailList.push({ name: '', language_id: null })
        this.setState({ detailList: detailList })
    }
    updateLanguageList(prevLanguageId, languageId) {
        let detailList = this.state.detailList.map(item => {
            let temp = Object.assign({}, item)
            if (temp.language_id === prevLanguageId) {
                temp.language_id = languageId
            }
            return temp
        })
        this.setState({ detailList: detailList.sort((a, b) => a.language_id - b.language_id) })
    }

    updateDescription(languageId, description) {
        let descriptionList = this.state.descriptionList.map(item => Object.assign({}, item))
        let index = this.state.descriptionList.findIndex(item => item.language_id === languageId)
        if (index !== -1) {
            descriptionList[index].description = description
        } else {
            descriptionList.push({
                language_id: languageId,
                description: description
            })
        }
        this.setState({ descriptionList: descriptionList })
    }

    onAddDetailList(languageId) {
        let detailList = this.state.detailList.map(item => Object.assign({}, item))
        detailList.push({ name: '', language_id: languageId })
        this.setState({ detailList: detailList })
    }

    onCloseNotificationManual() {
        this.setState({ visible: false })
        this.props.backpage()
    }

    addProductSku() {
        this.setState({
            visibleModalAddProductSku: true,
            productSkuCurrentItem: false
        })
    }

    updateProductSku(value, index) {
        const { productSku } = this.state
        if (index !== false) {
            const updatedProductSku = [...productSku];
            updatedProductSku[index] = value;
            this.setState({
                productSku: updatedProductSku,
                visibleModalAddProductSku: false,
                productSkuCurrentItem: false,
            });
        } else {
            productSku.push(value)
            this.setState({
                productSku: productSku,
                visibleModalAddProductSku: false,
                productSkuCurrentItem: false,
            })
        }
    }

    editProductSku(index) {
        let productSkuCurrentItem = this.state.productSku[index] ? this.state.productSku[index] : false
        productSkuCurrentItem.index = index
        this.setState({
            productSkuCurrentItem: productSkuCurrentItem,
            visibleModalAddProductSku: true
        })
    }
    deleteProductSku(index) {
        let productSku = this.state.productSku
        productSku.splice(index, 1);
        this.setState({
            productSku: productSku
        })
    }

    render() {
        const { visible, productSku, isNotUsePriceForAllProduct, productTitleList, productTitleId, videoDetailList,coursePoint, isUsePointIndividuallySku } = this.state
        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 24 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutInline = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };

        const product = productTitleList.find(item => item.id === productTitleId);
        const productTitleName = productTitleId ? product.name : 'Name'
        const columns = [
            // {
            //     title: 'Id',
            //     dataIndex: 'id',
            //     key: 'id',
            //     align: 'center ',
            //     // width: '10%',
            // },
            {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                align: 'left',
                width: '10%',
                render: (text, item, index) => <img
                    id="target"
                    style={{ width: '100%', height: '100%' }}
                    src={`${item && item.image && item.image[0] && item.image[0].view ? item.image[0].view : null}`}
                    onError={(image) => image.target.src = defaultSquareSrc}
                />
            },
            {
                title: (
                    <span style={{ display: "flex",gap: "5px", alignItems: "center",justifyContent: "center" }} >
                        External Product SKU Id 
                        <span style={{ display: "flex", width: "16px", height: "16px", border: "1px solid #8d8d8d",borderRadius: "50%",justifyContent:"center",alignItems: "center" }}>
                            <Tooltip title="Product SKU Id ที่สร้างจากระบบภายนอกของลูกค้า"><Icon type="info" style={{ fontSize: 13 }} /></Tooltip>
                        </span>
                    </span>
                ),
                dataIndex: 'external_product_sku_id',
                key: 'external_product_sku_id',
                align: 'center',
                width: '20%',
            },
            {
                title: productTitleId ? productTitleName : 'Name',
                dataIndex: 'attr_1',
                key: 'attr_1',
                align: 'left',
                width: '20%',
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'weight',
                align: 'left',
            },
            {
                title: 'Width',
                dataIndex: 'width',
                key: 'width',
                align: 'center',
            },
            {
                title: 'Length',
                dataIndex: 'length',
                key: 'length',
                align: 'center',
            },
            {
                title: 'Height',
                dataIndex: 'height',
                key: 'height',
                align: 'center',
            },
            {
                title: 'Inventory',
                dataIndex: 'inventory',
                key: 'inventory',
                align: 'center',
            },
            // {
            //     title: 'Purchase',
            //     dataIndex: 'purchase',
            //     key: 'purchase',
            //     align: 'center',
            // },
            ...(isUsePointIndividuallySku ? [{
                title: 'Point',
                dataIndex: 'product_point',
                key: 'product_point',
                align: 'center',
            }] : []),
            ...(isNotUsePriceForAllProduct ? [{
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                align: 'center',
            }] : []),
            ...(isNotUsePriceForAllProduct ? [{
                title: 'Price for sale',
                dataIndex: 'price_for_sale',
                key: 'price_for_sale',
                align: 'center',
            }] : []),
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                width: '120px',
                render: (text, item, index) =>
                    <div className='product-sku-column-action'>
                        <span onClick={() => this.editProductSku(index)} style={{ cursor: "pointer" }}><Icon type="edit" /> {'Edit'} </span>
                        <Popconfirm title='ยืนยันที่จะลบรายการนี้ ใช่ หรือ ไม่?' onConfirm={() => this.deleteProductSku(index)}>
                            <span style={{ cursor: 'pointer' }}>
                                <Icon type="delete" /> {'Delete'}
                            </span>
                        </Popconfirm>
                    </div>
            },
        ]
        const detailLanguageList = []
        this.state.detailList.map(row => {
            let index = detailLanguageList.findIndex(item => item.language_id === row.language_id)
            if (index !== -1) {
                detailLanguageList[index].detail_list.push(row)
            } else {
                detailLanguageList.push({
                    language_id: row.language_id,
                    detail_list: [row]
                })
            }
        })
        detailLanguageList.map(row => {
            let descriptionList = this.state.descriptionList.filter(item => item.language_id === row.language_id)
            row['description'] = descriptionList.length ? descriptionList[0].description : ''
        })
        return (
            <div style={{ backgroundColor: '#FFFFFF', height: '100vh' }}>
                <div className="header-card" >
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'left', marginBottom: 10, cursor: 'pointer' }} onClick={() => this.props.backpage()}>
                        <Icon style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} type="left" />
                        <span style={{ fontSize: 25, fontWeight: 500, color: 'rgb(72, 197, 235)' }} >{this.props.editId ? 'Edit  Product' : 'Create Product'}</span>
                    </Col>
                    <Col sm={24} md={12} lg={12} style={{ textAlign: 'right', marginBottom: 10 }}>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: '#ffffff', color: 'rgb(72, 197, 235)', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickCancel()}
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}
                            onClick={() => this.onClickOk()}
                        >
                            {'Save'}
                        </Button>
                        <Button
                            style={{ marginLeft: 2, marginRight: 2, backgroundColor: 'rgb(55, 168, 0)', color: '#ffffff', border: '1px solid rgb(55, 168, 0)' }}
                            onClick={() => this.onClickSaveAndSend()}
                        >
                            {'Save & Send'}
                        </Button>
                    </Col>
                </div>
                <Card className="cardstyle">
                    {/* <Meta title="Product" /> */}
                    <Form>
                        <Row gutter={24}>
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Name'} required={true}>
                                    <Input
                                        value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Category Product'} required={true}>
                                    <Select
                                        value={this.state.categoryProductId}
                                        onChange={(value) => this.setState({ categoryProductId: value })}
                                    >
                                        {this.state.categoryProductList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Category Chat'}>
                                    <Select
                                        value={this.state.categoryTicketId}
                                        onChange={(value) => this.setState({ categoryTicketId: value })}
                                    >
                                        {this.state.categoryTicketList.map((item, index) => <Option key={index} value={item.id}>{item.Name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            {/* // TODO:  ใช้คั่น point กับหัวข้อบน  */}
                            <Col sm={24} md={24} lg={24} xl={24} ></Col> 
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Point'}>
                                    <Input
                                        value={coursePoint}
                                        disabled={isUsePointIndividuallySku}
                                        type={'number'}
                                        onChange={(event) => isUsePointIndividuallySku ? null : this.setState({ coursePoint: event.target.value })}
                                    />
                                </FormItem>
                            </Col>

                            <Col sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Set Point SKU Individually'} required={true}>
                                    <Switch
                                        style={{ paddingTop: 0 }}
                                        checked={isUsePointIndividuallySku}
                                        onChange={(checked) => this.setState({ isUsePointIndividuallySku: checked })}
                                    />
                                </FormItem>
                            </Col>

                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Price'}>
                                    <Input
                                        value={this.state.price}
                                        disabled={isNotUsePriceForAllProduct}
                                        type={'number'}
                                        onChange={(event) => isNotUsePriceForAllProduct ? null : this.setState({ price: event.target.value })}
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={24} md={12} lg={6}  >
                                <FormItem {...formItemLayout} label={'Price for sale'}>
                                    <Input
                                        value={this.state.priceForSale}
                                        disabled={isNotUsePriceForAllProduct}
                                        type={'number'}
                                        onChange={(event) => isNotUsePriceForAllProduct ? null : this.setState({ priceForSale: event.target.value })}
                                    />
                                </FormItem>
                            </Col>


                            <Col sm={24} md={12} lg={6} xl={6} >
                                <FormItem {...formItemLayout} label={'Product title name'} >
                                    <Select
                                        value={this.state.productTitleId}
                                        onChange={(value) => this.setState({ productTitleId: value })}
                                    >
                                        {this.state.productTitleList.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Set Pricing SKU Individually'} required={true}>
                                    <Switch
                                        style={{ paddingTop: 0 }}
                                        checked={isNotUsePriceForAllProduct}
                                        onChange={(checked) => this.setState({ isNotUsePriceForAllProduct: checked })}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "end" }}>
                                <Button
                                    onClick={() => this.addProductSku()}
                                    style={{
                                        // width: '50%',
                                        backgroundColor: 'rgb(72, 197, 235)',
                                        color: '#ffffff',
                                        border: '1px solid rgb(72, 197, 235)'
                                    }}>
                                    {'Add Product SKU'}
                                </Button>
                            </Col>
                            <Col sm={24} md={24} lg={24} xl={24} >
                                <FormItem {...formItemLayout} label={'Product SKU'}>
                                    <Table
                                        className='product-sku-table'
                                        dataSource={productSku}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </FormItem>
                            </Col>
                            <ProductSkuModal
                                // editRole={this.props.editRole}
                                // type={this.state.type}
                                handleOk={(value, index) => this.updateProductSku(value, index)}
                                isUsePointIndividuallySku={isUsePointIndividuallySku}
                                isNotUsePriceForAllProduct={isNotUsePriceForAllProduct}
                                visible={this.state.visibleModalAddProductSku}
                                onClose={() => this.setState({ visibleModalAddProductSku: false })}
                                productSkuCurrentItem={this.state.productSkuCurrentItem}
                                productTitleName={productTitleName}

                            // pricingCurrentItem={this.state.pricingCurrentItem}
                            // coursesList={this.state.coursesList}
                            // renewSettingGlobal={this.state.renewSettingGlobal}
                            // handleCancel={() => this.setState({visibleModalAddProductSku: !this.state.visibleModalAddProductSku})}
                            // editId={this.props.editId}
                            />
                        </Row>

                        {/*  
                            // <Fragment>
                            //     <Row gutter={24}>
                            //         <Col sm={24} md={12} lg={3} xl={3} >
                            //             <FormItem {...formItemLayout} label={'Weight'}>
                            //                 <Input
                            //                     value={this.state.weight}
                            //                     type={'number'}
                            //                     onChange={(event) => this.setState({ weight: event.target.value })}
                            //                 />
                            //             </FormItem>
                            //         </Col>
                            //         <Col sm={24} md={12} lg={3} xl={3} >
                            //             <FormItem {...formItemLayout} label={'Total'}>
                            //                 <Input
                            //                     value={this.state.total}
                            //                     type={'number'}
                            //                     onChange={(event) => this.setState({ total: event.target.value })}
                            //                 />
                            //             </FormItem>
                            //         </Col>
                            //         <Col sm={24} md={12} lg={2} xl={2} >
                            //             <FormItem {...formItemLayout} label={'Purchase'}>
                            //                 <Input
                            //                     value={this.state.purchase}
                            //                     type={'number'}
                            //                     onChange={(event) => this.setState({ purchase: event.target.value })}
                            //                 />
                            //             </FormItem>
                            //         </Col>
                            //     </Row>
                            //     <Row gutter={24}>
                            //         <Col sm={24} md={12} lg={6}  >
                            //             <FormItem {...formItemLayout} label={'Inventory'}>
                            //                 <Input
                            //                     value={this.state.inventory}
                            //                     type={'number'}
                            //                     onChange={(event) => this.setState({ inventory: event.target.value })}
                            //                 />
                            //             </FormItem>
                            //         </Col>
                            //         <Col sm={24} md={12} lg={6}  >
                            //             <FormItem {...formItemLayout} label={'Price'}>
                            //                 <Input
                            //                     value={this.state.price}
                            //                     type={'number'}
                            //                     onChange={(event) => this.setState({ price: event.target.value })}
                            //                 />
                            //             </FormItem>
                            //         </Col>
                            //         <Col sm={24} md={12} lg={6}  >
                            //             <FormItem {...formItemLayout} label={'Price for sale'}>
                            //                 <Input
                            //                     value={this.state.priceForSale}
                            //                     type={'number'}
                            //                     onChange={(event) => this.setState({ priceForSale: event.target.value })}
                            //                 />
                            //             </FormItem>
                            //         </Col>
                            //     </Row>
                            // </Fragment>
                        */}

                        {/* <Row gutter={24}>
                            <Col sm={24} md={24} lg={24} style={{ textAlign: "end", marginBottom: 10 }} >
                                <Button onClick={() => this.onAddLanguageList()} style={{ width: '25%', backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}>{'+ Add'}</Button>
                            </Col>
                        </Row> */}
                        {detailLanguageList.map((row, i) => {
                            return (
                                <Row style={{ padding: 10, border: "1px solid #d9d9d9", borderRadius: 5, marginBottom: 10 }}>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6} >
                                        <FormItem {...formItemLayoutInline} label={'Line ID'}>
                                            <p style={{ margin: 0, fontSize: 14, padding: 5 }}>{`0000${i + 1}`}</p>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                        <FormItem {...formItemLayoutInline} label={'Language'} required={true}>
                                            <Select
                                                allowClear={true}
                                                value={row.language_id}
                                                onChange={(value) => this.updateLanguageList(row.language_id, value)}
                                            >
                                                {this.state.languageList.length ?
                                                    this.state.languageList.map((item, index) => <Option key={index} value={item.id} disabled={detailLanguageList.map(r => r.language_id).includes(item.id)} >{item.name}</Option>)
                                                    : null}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ textAlign: "end" }}>
                                        <Button onClick={() => this.onAddDetailList(row.language_id)} style={{ width: '50%', backgroundColor: 'rgb(72, 197, 235)', color: '#ffffff', border: '1px solid rgb(72, 197, 235)' }}>{'Add Detail'}</Button>
                                    </Col>
                                    {/* <Row gutter={24}>
                                        
                                        <FormItem {...formItemLayout} label={'Detail'} style={{ marginTop: '30px' }}>
                                            <TextArea
                                                autosize={{ minRows: 6 }}
                                                value={this.state.detail}
                                                onChange={(event) => this.setState({ detail: event.target.value })}

                                            // onChange={(event) => this.updateDescription(row.language_id, event.target.value)}
                                            />
                                        </FormItem>
                                    </Row> */}
                                    <Col sm={24} md={24} lg={24} >
                                        <FormItem {...formItemLayout} label={'Detail'} style={{ marginTop: '30px' }}>
                                            <TextArea
                                                autosize={{ minRows: 6 }}
                                                value={row.description}
                                                onChange={(event) => this.updateDescription(row.language_id, event.target.value)}
                                            />
                                        </FormItem>
                                    </Col>
                                    {row.detail_list.map((item, index) => {
                                        return (
                                            <Col sm={24} md={24} lg={24} style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }} >
                                                <Input
                                                    value={item.name}
                                                    onChange={(event) => this.onChangeNameDetailList(row.language_id, index, event.target.value)}
                                                />
                                                <Button onClick={() => this.onDeleteDetailList(index)} style={{ marginLeft: '5px', backgroundColor: '#ff888e', color: '#ffffff', border: '1px solid #ff888e' }}>{'Delete'}</Button>
                                            </Col>
                                        )
                                    })}

                                    {/* <Col sm={24} md={24} lg={24} >
                                        <FormItem {...formItemLayout} label={'Detail'} style={{ marginTop: '30px' }}>
                                            <TextArea
                                                autosize={{ minRows: 6 }}
                                                value={row.description}
                                                onChange={(event) => this.updateDescription(row.language_id, event.target.value)}
                                            />
                                        </FormItem>
                                    </Col> */}
                                </Row>
                            )
                        })}

                        <Row gutter={24}>
                            <Col sm={24} md={24} lg={24} >


                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <FormItem {...formItemLayout} label={'Cover Image'} style={{ marginTop: '30px' }}>
                                            <ImageUpload
                                                label={'Upload cover image'}
                                                limit={1}
                                                dataSource={this.state.imageCoverList}
                                                updateDataSource={(dataSource) => this.setState({ imageCoverList: dataSource })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={24} md={24} lg={24}>
                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <FormItem {...formItemLayout} label={'Detail Video'} extra="ขนาดวิดีโอแนะนำ 1024x1024" style={{ marginTop: '30px' }}>
                                            <VideoUpload
                                                className={'video-product-page'}
                                                label={'Upload video'}
                                                limit={1}
                                                dataSource={videoDetailList}
                                                isSaveDelete={true}
                                                updateDeleteFileList={(dataSource)=>this.setState(prev=>({ deleteFileList: [...prev.deleteFileList, ...dataSource]}))}
                                                updateDataSource={(dataSource) => this.setState({ videoDetailList: dataSource })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={24} md={24} lg={24}>
                                <Row>
                                    <Col sm={24} md={24} lg={24} style={{ flexDirection: 'row' }}>
                                        <FormItem {...formItemLayout} label={'Detail Image'} extra="ขนาดรูปภาพแนะนำ 1024x1024 px" style={{ marginTop: '30px', marginBottom: '100px' }}>
                                            <ImageUpload
                                                label={'Upload detail image'}
                                                limit={99}
                                                dataSource={this.state.imageDetailList}
                                                updateDataSource={(dataSource) => this.setState({ imageDetailList: dataSource })}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <NotificationManualModal
                    visible={visible}
                    notificationType={'product'}
                    productId={this.state.productId}
                    onClose={() => this.onCloseNotificationManual()}
                />
                <LoadingModal
                    visible={this.state.loading}
                    onClose={() => this.setState({ loading: false })}
                />
                {/* <UploadImageModal
                    visible={this.state.uploading}
                    onClose={() => this.setState({ uploading: false })}
                /> */}
            </div >
        );
    }
}

Product.propTypes = {
    editId: PropTypes.number,
}

Product.defaultProps = {
    editId: null,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    administratorId: state.login.id,
    folderName: state.login.folderName,
})

export default connect(mapStateToProps)(Product);

