import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import moment from 'moment';
import './style.scss'

const FormItem = Form.Item;

class PaymentGatewayInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { 
            prefixInvoice,
            application_id_one_title, application_id_two_title, application_id_three_title,
            application_id_one, application_id_two, application_id_three } = this.props
        const today = moment().format("YYMMDD")

        const formItemLayout = {
            // labelCol: {
            //     xs: { span: 8 },
            //     sm: { span: 4 },
            // },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <div className='payment-gateway-input-container-wrapper'>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <FormItem 
                            {...formItemLayout} 
                            label={"Prefix invoice"}
                            extra={`ตัวอย่าง: ${prefixInvoice ? prefixInvoice : ""}${today}00001`}
                        >
                            <Input
                                value={prefixInvoice}
                                onChange={(event) => this.props.updateState({ prefixInvoice: event.target.value })}
                                maxLength={15}
                            />
                        </FormItem>
                    </Col>
                    {
                        application_id_one_title ? 
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={application_id_one_title}>
                                <Input
                                    value={application_id_one}
                                    onChange={(event) => this.props.updateState({ application_id_one: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                        :
                        null
                    }
                    
                    {
                        application_id_two_title ? 
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={application_id_two_title}>
                                <Input
                                    value={application_id_two}
                                    onChange={(event) => this.props.updateState({ application_id_two: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                        :
                        null
                    }
                    
                    {
                        application_id_three_title ? 
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <FormItem {...formItemLayout} label={application_id_three_title}>
                                <Input
                                    value={application_id_three}
                                    onChange={(event) => this.props.updateState({ application_id_three: event.target.value })}
                                />
                            </FormItem>
                        </Col>
                        :
                        null
                    }
                    
                </Row>
            </div>
        );
    }
}


export default PaymentGatewayInput;

