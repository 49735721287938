

import { del, get } from './index'


export const deleteFileInServer = (fileName) => {
    return del('/api/crm/customer/upload/file', { fileName })
        .then((response) => {
            return response
        })
        .catch(err => {
            console.log(err.message)
            return err
        })
}



export const getProvince = () => {
    let url = '/api/crm/administrator/provinces';
    return get(url)
        .then(response => {
            if(response.success){
                return response.data
            }
            return []
        })
        .catch(err => {
            console.log(err.message)
            throw err
        })
}

export const getDistrict = (provinceId = null) => {
    let url = '/api/crm/administrator/districts';
    if(provinceId){
        url += '?province=' + provinceId
    }
    return get(url)
        .then(response => {
            if(response.success){
                return response.data
            }
            return []
        })
        .catch(err => {
            console.log(err.message)
            throw err
        })
}

export const getSubDistrict = (districtId = null) => {
    let url = '/api/crm/administrator/subdistricts';
    if(districtId){
        url += '?districts='+districtId
    }
    return get(url)
        .then(response => {
            if (response.status >= 400) {
                throw new Error("Bad response from server");
            }
            if(response.success){
                return response.data
            }
            return []
        })
        .catch(err => {
            console.log(err.message)
            throw err
        })
}