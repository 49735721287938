import React from 'react'
import { Table, DatePicker, LocaleProvider, Select, Dropdown, Menu, Icon, Pagination, notification, Col, Input, Checkbox, Button, Switch,Tooltip } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import 'antd/dist/antd.min.css'
import './style.scss'
import moment from 'moment'
import { put, patch } from '../../utils'
import { setContentCustomTable, filteringCustomTable, sortingCustomTable } from './operator'
import { callOderInvoicePDF, callOderReceiptPDF } from '../../pdf/adapter'
// import console = require('console');
// import console = require('console');
const { Search } = Input;

const Option = Select.Option;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const nonEditablePages = ["Point"];


const getUnique = (p, c) => {
    if (p.includes(c)) return p;
    return p.concat(c);
}

const OverlayMenu = ({ onClick, columns, unselected, isSorting, selected, isRight }) => (
    <Menu className={isRight ? 'last' : ''} key="menu" onClick={onClick} selectedKeys={selected}>
        <Menu.Item key="asc"><Icon type="arrow-up" /> Sort Ascending</Menu.Item>
        <Menu.Item key="desc"><Icon type="arrow-down" /> Sort Descending</Menu.Item>
        {isSorting && <Menu.Item key="clear"><Icon /> Clear Sort</Menu.Item>}
        <SubMenu key="filter" title={[<Icon type="filter" />, <span>Filter using</span>]}>
            <MenuItemGroup key="sub">
                <Menu.Item key="contain">Contain (default)</Menu.Item>
                <Menu.Item key="equal">Equal</Menu.Item>
                <Menu.Item key="not equal">Not equal</Menu.Item>
                <Menu.Item key="less than">Less than</Menu.Item>
                <Menu.Item key="greater than">Greater than</Menu.Item>
                <Menu.Item key="less than or">Less than or equal to</Menu.Item>
                <Menu.Item key="greater than or">Greater than or equal to</Menu.Item>
            </MenuItemGroup>
        </SubMenu>
        {/* <SubMenu key="columns" title={[<Icon type="layout" />, <span>Column</span>]}>
            <MenuItemGroup>
                {columns.map(column => {
                    return <Menu.Item key={column}><input type="checkbox" checked={!unselected.includes(column)} /> {column}</Menu.Item>
                })}
            </MenuItemGroup>
        </SubMenu> */}
    </Menu>
);


const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
};

class CustomTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            dataTable: {},

            filters: {},
            page: 1,
            limit: 50,
            total: 1,
            // selected: [],
            columns: [],
            columnActive: null,
            display: [],
            bodyFilters: {
                sorting: {
                    key: '',
                    sort: 'asc',
                },
                filters: {},
                columns: ['id'],
            },
            columnWidth: [
                {
                    name: "Ticket Chat",
                    width: ['10%', '10%', '10%', '10%', '20%', '10%', '14%', '8%', '8%']
                },
                {
                    name: "Category Chat",
                    width: ['20%', '40%', '20%', '20%']
                },
                {
                    name: "Tag",
                    width: ['20%', '40%', '20%', '20%']
                },
                {
                    name: "Product",
                    width: ['9%', 'auto', '8%', '8%', '5%', '7%', '7%', '13%', '5%', '5%', '5%', '10%']
                },
                {
                    name: "Category Product",
                    width: ['15%', '40%', '15%', '15%', '15%']
                },
                {
                    name: "Gallery",
                    width: ['15%', '30%', '35%', '20%']
                },
                {
                    name: "ZZZZZ",
                    width: ['7%', '15%', '15%', '10%', '40%', '13%']
                },
                {
                    name: "Article",
                    width: ['15%', '20%','20%', '15%', '15%', '15%']
                },
                {
                    name: "Role Staff",
                    width: ['30%', '70%']
                },
                {
                    name: "User Staff",
                    width: ['10%', '20%', '20%', '20%', '15%', '15%']
                },
                {
                    name: "User Customer",
                    width: ['15%', '30%', '35%', '20%']
                },
            ],
            // ...props.columns.reduce((p, c) => Object.assign(p, { [c]: '' }), {}),
        }
        this.inputs = {}
    }

    componentDidMount() {
        this.setState(setContentCustomTable(this.state, { rows: this.props.content, page: this.state.page }))
        // this.props.updateObjectFound(this.props.total)
    }

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         columns: nextProps.columns,
    //         ...nextProps.columns.reduce((p, c) => Object.assign(p, { [c]: nextProps.filters[c] || '' }), {}),
    //     })
    // }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.content != prevProps.content) {
            this.setState(setContentCustomTable(this.state, { rows: this.props.content, page: this.state.page }))

        }
    }

    setFilterState(key, value) {
        const filters = Object.assign({}, this.state.filters);
        filters[key] = value;
        this.setState(filteringCustomTable(this.state, { filters: filters }))
    }

    clearFilter() {
        this.state.columns.forEach((column) => {
            if (this.inputs[column]) {
                this.inputs[column].value = ''
            }
        })
        this.props.dispatch({
            type: 'TABLE_CLEAR_FILTER',
            payload: {
                category: this.props.type,
            }
        })
        this.setState({
            bodyFilters: {
                sorting: {
                    key: '',
                    sort: 'asc',
                },
                filters: {},
                columns: ['id'],
            },
            filters: {},
        })
    }
    sortingMenu(column, value) {
        this.setState(sortingCustomTable(this.state, { column, value }))
        if (value.key === 'clear') {
            return this.setState({
                columnActive: null,
                bodyFilters: Object.assign(this.state.bodyFilters, { sorting: { key: '', sort: 'asc' } })
            })
        }
        if (value.keyPath.includes('filter')) {
            const { filters } = this.state.bodyFilters
            if (filters[column] === value.key) {
                const newFilters = Object.keys(filters).filter(key => key !== column).reduce((p, c) => Object.assign(p, c), {})
                return this.setState({
                    columnActive: null,
                    bodyFilters: Object.assign(this.state.bodyFilters, { filters: newFilters })
                })
            }
            return this.setState({
                columnActive: null,
                bodyFilters: Object.assign(this.state.bodyFilters, { filters: { ...this.state.bodyFilters.filters, [column]: value.key } })
            })
        } else if (value.keyPath.includes('columns')) {
            const { columns } = this.state.bodyFilters;
            if (columns.includes(value.key)) {
                return this.setState({
                    columnActive: null,
                    bodyFilters: Object.assign(this.state.bodyFilters, { columns: columns.filter(c => c !== value.key) })
                })
            }
            return this.setState({
                columnActive: null,
                bodyFilters: Object.assign(this.state.bodyFilters, { columns: columns.concat(value.key).reduce(getUnique, []) })
            })
        }
        return this.setState({
            columnActive: null,
            bodyFilters: Object.assign(this.state.bodyFilters, { sorting: { key: column, sort: value.key } })
        })
    }

    prefixFilter(type) {
        switch (type) {
            case 'equal': {
                return '=  '
            }
            case 'not equal': {
                return '!=  '
            }
            case 'less than': {
                return '<  '
            }
            case 'greater than': {
                return '>  '
            }
            case 'less than or': {
                return '<=  '
            }
            case 'greater than or': {
                return '>=  '
            }
            default: return ''
        }
    }
    renderColumn(column) {
        if (column.includes('Date') || column.includes('ETA') || column.includes('ETD')) {
            return <DatePicker
                size="small"
                value={this.state.filters[column] && moment(this.state.filters[column], 'YYYY-MM-DD')}
                format="DD/MM/YYYY"
                onChange={date => this.setFilterState([column], date ? date.format('YYYY-MM-DD') : '')} />
        }
        if (['Status', 'Position', 'Third Party Claim', 'Require Spare Part', 'Type', 'Repair Status', 'EDI Status', 'Approved Status'].includes(column)) {
            return (<Select
                size="small"
                allowClear
                style={{ width: '100%' }}
                value={this.state.filters[column]}
                onChange={v => this.setFilterState([column], v || '')}
            >
                {this.state.rows.reduce((p, c) => p.includes(c[column]) ? p : p.concat(c[column]), []).filter(c => c !== null).map(c => <Option key={c} value={c}>{c}</Option>)}
            </Select>)
        }
        return <Search
            size="small"
            value={this.state[column]}
            onChange={e => this.setState({ [column]: e.target.value })}
            ref={x => this.inputs[column] = x}
            onBlur={e => this.setFilterState([column], e.target.value)}
            onKeyPress={e => {
                if (e.which === 13) {
                    this.setFilterState([column], e.target.value)
                }
            }}
        // className="filter-input"
        />
    }

    rowClicked(id) {
        this.props.rowClicked(id)
    }
    toggleItem(record) {
        let selectList = Object.assign([], this.props.selectList)
        let index = this.props.selectList.findIndex(row => row.id == record.id)
        if (index == -1) {
            selectList.push(record)
        } else {
            selectList.splice(index, 1)
        }
        this.props.setSelectList(selectList)
    }

    isCheckedCheckbox(record) {
        let index = this.props.selectList.findIndex(row => row.id == record.id)
        return index != -1
    }

    get columns() {
        const filterColumns = this.state.bodyFilters.columns
        let columns = this.state.columns.filter(column => !filterColumns.includes(column))
        let width = `${1 / columns.length * 100}%`
        let columnWidthName = this.props.pageName
        let findIndex = this.state.columnWidth.findIndex(item => item.name == columnWidthName)
        return columns.map((column, index) => ({
            title: (
                <div key={index}>
                    <div>
                        {this.renderColumn(column)}
                    </div>
                    <div className="column-title">
                        {index == 0 && this.props.checkbox ?
                            <span>
                                <Checkbox
                                    style={{ marginRight: 12 }}
                                    id={`column_${index}`}
                                    checked={this.state.display.length == this.props.selectList.length}
                                    onClick={() => this.state.display.length == this.props.selectList.length ? this.props.setSelectList([]) : this.props.setSelectList(Object.assign([], this.state.display))}
                                />
                            </span>
                            : ''}
                        {this.prefixFilter(this.state.bodyFilters.filters[column])}
                        {column}
                        {this.state.bodyFilters.sorting.key === column && <Icon type={this.state.bodyFilters.sorting.sort === 'asc' ? 'arrow-up' : 'arrow-down'} />}
                        <div className={this.state.columnActive === column ? "column-icon active" : "column-icon"}>
                            <Dropdown
                                trigger={['click']}
                                placement="bottomLeft"
                                onVisibleChange={isVisible => this.setState({ columnActive: isVisible ? column : null })}
                                overlay={<OverlayMenu
                                    selected={[this.state.bodyFilters.filters[column]]}
                                    key={column}
                                    isRight={index === (columns.length - 1) || index === (columns.length - 2)}
                                    isSorting={this.state.bodyFilters.sorting.key === column}
                                    onClick={e => {
                                        this.sortingMenu(column, e)
                                    }}
                                    columns={this.state.columns}
                                    unselected={this.state.bodyFilters.columns} />}
                            ><div className="icon"><Icon type="caret-down" /></div>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            ),
            dataIndex: column,
            key: column,
            width: findIndex != -1 ? this.state.columnWidth[findIndex].width[index] : width,
            render: (t, record) => {
                if (index === 0) {
                    return (
                        <div key={t} className={record.id === this.props.selectedItem ? 'select-item' : ''}>
                            {this.props.checkbox ?
                                <span>
                                    <Checkbox
                                        id={`row_${columns}`}
                                        onClick={() => this.toggleItem(record)}
                                        checked={this.isCheckedCheckbox(record)}
                                    />
                                    <div onClick={() => this.rowClicked(record.id)} style={{ marginLeft: 35, width: '100%', cursor: 'pointer' }}>{t}</div>
                                </span>
                                :
                                <div 
                                    style={{ cursor: nonEditablePages.includes(this.props.pageName) ? 'not-allowed' : 'pointer' }} 
                                    onClick={() => nonEditablePages.includes(this.props.pageName) ? null : this.rowClicked(record.id)}
                                >
                                {t}
                                </div>
                            }
                        </div>
                    )
                } else {
                    if (this.props.pageName == 'Manual Send' && columns[index] == 'Send') {
                        if (record['Send'] == 'save') {
                            return (
                                <Button style={{ width: '100%', border: '1px solid #47C5EB', color: '#47C5EB' }} onClick={() => this.onClickManualSend(record.id)}> {"Send"} </Button>
                            )
                        } else {
                            return (
                                <Button style={{ width: '100%' }} disabled={true}> {"Send"} </Button>
                            )
                        }
                    }
                    if (this.props.pageName == 'Sales Order' && columns[index] == 'Payment Status') {
                        return (this.showColumnPaymentStatus(columns[index], t, record))
                    }
                    if (this.props.pageName == 'Sales Order' && columns[index] == 'Delivery Status') {
                        return (this.showColumnDeliveryStatus(columns[index], t, record))
                    }
                    if (this.props.pageName == 'Sales Order' && columns[index] == 'Confirm to Receive') {
                        return (this.showColumnConfirmToReceive(columns[index], t, record))
                    }
                    if (this.props.pageName == 'Sales Order' && columns[index] == 'Tracking Number') {
                        return (this.showColumnTrackingNumber(columns[index], t, record,index))
                    }
                    if (this.props.pageName == 'Sales Order' && columns[index] == 'Invoice') {
                        if(t === 1){
                            // TODO: check ให้ Print invoice ขึ้นถ้ามีการขอ
                            return <div style={{ cursor: 'pointer', textAlign: 'center', color: '#2196f3' }} onClick={() => callOderInvoicePDF(record.id)}>{'Print'}</div>
                        }
                        return
                    }
                    if (this.props.pageName == 'Sales Order' && columns[index] == 'Receipt') {
                        return <div style={{ cursor: 'pointer', textAlign: 'center', color: '#2196f3' }} onClick={() => callOderReceiptPDF(record.id)}>{'Print'}</div>
                    }
                    if (this.props.pageName == 'Auto Send' && columns[index] == 'status') {
                        return (
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={record['status'] == 1}
                                onChange={(checked) => this.onClickStatusAutoSend(record.id, checked)} />
                        )
                    }
                    if (this.props.pageName == 'Article' && columns[index] == 'Publicity') {
                        return (
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={record['Publicity'] == 1}
                                onChange={(checked) => this.onClickChangePublicity(record.id, checked)} />
                        )
                    }
                    if (this.props.pageName == 'Delivery Method' && columns[index] == 'Default') {
                        return (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={record['Default'] == 1}
                                onChange={(checked) => this.onClickChangeDeliveryMethodDefault(record.id, checked)} />
                        )
                    }
                    if (this.props.pageName == 'Payment Method' && columns[index] == 'Default') {
                        return (
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={record['Default'] == 1}
                                onChange={(checked) => this.onClickChangePaymentMethodDefault(record.id, checked)} />
                        )
                    }
                    else {
                        return (this.showColumnData(columns[index], t, record))
                    }

                }
            }
        }))
    }

    showColumnPaymentStatus(item, t, record) {
        const onClick = () => this.props.subComponentClicked(record.id, 'Payment Order')
        //TODO: pending-cal-shipping-fee ไม่ใช้สถานะจาก database แตเป็น display โดยเช็คจาก is_calculate_delivery_price = 0
        switch (t) {
            case 'pending-cal-shipping-fee':
                return (
                <div>
                    <Tooltip title="Awaiting Shipping Calc">
                        <Button className='sales-order-table-btn' style={{ color: '#fff', backgroundColor: '#ffd34e' }} onClick={() => nonEditablePages.includes(this.props.pageName) ? null : this.rowClicked(record.id)} >
                            {'Awaiting Shipping Calc'}
                        </Button>
                    </Tooltip>
                </div>
                )
            case 'pending-payment':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#FAAD14' }} onClick={onClick}>{'Pending Payment'}</Button></div>
            case 'pending-approve':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#0074FF' }} onClick={onClick}>{'Pending Approve'}</Button></div>
            case 'approve':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#008000' }} onClick={onClick}>{'Approve'}</Button></div>
            case 'reject':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#EB3A17' }} onClick={onClick}>{'Reject'}</Button></div>
            case 'cancel':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#EB3A17' }} onClick={onClick}>{'Cancel'}</Button></div>
            default:
                return <div><Button style={{ width: "100%", cursor: 'default' }}>{t}</Button></div>
        }
    }

    showColumnDeliveryStatus(item, t, record) {
        const menu = (
            <Menu onClick={() => null}>
                <Menu.Item key="1">
                    <Button onClick={() => this.onClickSentDeliveryStatus(record)} style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#008000' }} >{'Sent'}</Button>
                </Menu.Item>
            </Menu>
        );
        switch (t) {
            case 'pending':
                return <div> <Dropdown overlay={menu}><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#FAAD14' }} >{'Pending'}<Icon type="down" /></Button></Dropdown></div>
            case 'sent':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#008000' }} >{'Sent'}</Button></div>
            case 'cancel':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#EB3A17' }} >{'Cancel'}</Button></div>
            default:
                return <div><Button style={{ width: "100%", cursor: 'default' }}>{t}</Button></div>
        }
    }
    showColumnConfirmToReceive(item, t, record) {
        const menu = (
            <Menu onClick={() => null}>
                <Menu.Item key="1">
                    <Button onClick={() => this.onClickReceive(record)} style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#008000' }} >{'Confirm'}</Button>
                </Menu.Item>
            </Menu>
        );
        switch (t) {
            case 'pending':
                return <div> <Dropdown overlay={menu}><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#FAAD14' }} >{'Pending'}<Icon type="down" /></Button></Dropdown></div>
            case 'confirm':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#008000' }} >{'Confirm'}</Button></div>
            case 'cancel':
                return <div><Button style={{ width: "100%", cursor: 'pointer', color: '#fff', backgroundColor: '#EB3A17' }} >{'Cancel'}</Button></div>
            default:
                return <div><Button style={{ width: "100%", cursor: 'default' }}>{t}</Button></div>
        }
    }

    showColumnTrackingNumber(item, t, record) {
        return (
            <div>
                
                <Input value={t} onChange={(e)=>this.changeTrackingNumber(record,e.target.value)} disabled={record['Delivery Status'] == 'sent'}/>
            </div>
        )
    }

    changeTrackingNumber(record,value){
        let tempDisplay = Object.assign([],this.state.display) 
        let index = tempDisplay.findIndex(item=> item.id == record.id)
        tempDisplay[index]['Tracking Number'] = value
        this.setState({
            display: tempDisplay
        })
        
    }

    showColumnData(item, t, record) {
        const columnStyle = this.props.columnStyle ? this.props.columnStyle : []
        let index = columnStyle.findIndex(column => column.column == item)
        if (index != -1) {
            switch (columnStyle[index].style) {
                case 'priceNumber':
                    return this.setPriceNumber(t, record)
                default:
                    return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{t}</div>
            }
        } else if (item == "จำนวนวันที่รอ" && t != '-') {

            return <div style={{ cursor: 'pointer', color: 'red' }} onClick={() => this.rowClicked(record.id)}>{t}</div>


        } else if (item == "Status") {
            if (t == "wait administrator") {
                return <div onClick={() => this.rowClicked(record.id)} style={{ cursor: 'pointer', color: '#fff', backgroundColor: '#EB3A17', display: 'inline', borderRadius: '4px', fontSize: '11px', textAlign: 'center', paddingLeft: '0px' }}> Waiting admin</div>
            }
            if (t == "wait customer") {
                return <div onClick={() => this.rowClicked(record.id)} style={{ cursor: 'pointer', color: '#fff', backgroundColor: '#40a9ff', display: 'inline', borderRadius: '4px', fontSize: '11px', textAlign: 'center', paddingLeft: '0px' }}> Waiting Customer</div>
            }
            if (t == "pending") {
                return <div onClick={() => this.rowClicked(record.id)} style={{ cursor: 'pointer', color: '#fff', backgroundColor: '#53B7B7', display: 'inline', borderRadius: '4px', fontSize: '11px', textAlign: 'center', paddingLeft: '0px' }}> In progress</div>
            }
            if (t == "close") {
                return <div onClick={() => this.rowClicked(record.id)} style={{ cursor: 'pointer', color: '#fff', backgroundColor: '#1FD963', display: 'inline', borderRadius: '4px', fontSize: '11px', textAlign: 'center', paddingLeft: '0px' }}> Close</div>
            }

        } else if (item == "Day waiting") {
            return <div style={{ cursor: 'pointer', color: 'EB3A17' }} onClick={() => this.rowClicked(record.id)}>{t}</div>

        }
        else if (item == "Last Message" && t != null) {

            if (t.slice(0, 2) == "#I") {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{'Send picture'}</div>
            }
            if (t.slice(0, 2) == "#P") {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{'Send product'} </div>
            }
            if (t.slice(0, 2) == "#V") {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{'Send video'}</div>
            }
            if (t.slice(0, 2) == "#C") {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{'Send Category'}</div>
            }
            if (t.slice(0, 2) == "#R") {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{'Send Link register'}</div>
            }
            if (t.slice(0, 2) == "#F") {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{'Send file'}</div>
            }
            else {
                return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{t}</div>
            }

        }
        else if (item == "Ticket Name" && t != null) {
            return <div style={{ cursor: 'pointer' }} onClick={() => this.rowClicked(record.id)}>{t.slice(0, 20)}</div>
        }
        else {
            return <div style={{ cursor:  nonEditablePages.includes(this.props.pageName) ? 'not-allowed' : 'pointer' }} onClick={() => nonEditablePages.includes(this.props.pageName) ? null : this.rowClicked(record.id)}>{t}</div>
        }
    }



    setPriceNumber(price, record) {
        let priceString = parseFloat(price).toFixed(1)
        let result = ''
        if (parseFloat(price) == parseInt(price)) {
            result = parseFloat(priceString).toLocaleString() + '.0'
        } else {
            result = parseFloat(priceString).toLocaleString()
        }
        return (
            <div
                style={{ textAlign: 'right', cursor: 'pointer' }}
                onClick={() => this.rowClicked(record.id)}
                key={price}
            >
                {result}
            </div>)

    }

    onClickManualSend(id) {
        let url = '/api/crm/administrator/notification/manual/send/' + id
        let data = {}
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Send success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Send error.')
            })
    }

    onClickChangePublicity(id, checked) {
        let url = '/api/article/publicity/' + id
        let data = { publicity: checked }
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Change publicity success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Change publicity error.')
            })
    }
    onClickChangeDeliveryMethodDefault(id, checked) {
        let url = '/api/delivery/method/isdefault/' + id
        let data = { is_default: checked }
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Change default success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Change default error.')
            })
    }
    onClickChangePaymentMethodDefault(id, checked) {
        let url = '/api/payment/method/isdefault/' + id
        let data = { is_default: checked }
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Change default success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Change default error.')
            })
    }

    onClickStatusAutoSend(id, checked) {
        let url = '/api/notification/auto/send/status/' + id
        let data = { status: checked }
        put(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Change status success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Change status error.')
            })
    }

    onClickSentDeliveryStatus(item) {
        if(!item['Tracking Number']){
            openNotificationWithIcon('error', 'System notification', 'Please enter Tracking Number')
            return
        }
        let url = '/api/crm/administrator/sales/order/' + item.id + '/delivery/status'
        let data = { 
            status: 'sent',
            tracking_number: item['Tracking Number']
        }
        patch(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Change status success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Change status error.')
            })
    }

    onClickReceive(item) {
        if(item['Delivery Status'] != 'sent'){
            openNotificationWithIcon('error', 'System notification', 'Please Select Delivery Status to Sent')
            return
        }
        let url = '/api/crm/administrator/sales/order/' + item.id + '/receive/status'
        let data = { 
            status: 'confirm'
        }
        patch(url, data)
            .then(response => {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                openNotificationWithIcon('success', 'System notification', 'Change status success.')
                this.props.refreshPage()
            })
            .catch(err => {
                console.log(err.message)
                openNotificationWithIcon('error', 'System notification', 'Change status error.')
            })
    }


    onChangePage(page) {
        this.setState(setContentCustomTable(this.state, { rows: this.props.content, page: page }))
    }

    render() {
        return (
            <LocaleProvider locale={enUS}>
                <div>
                    <div className="table-width-forresponsive">
                        <Table
                            className={`table-${this.props.pathName.slice(1)}`}
                            pagination={false}
                            loading={this.props.loading ? this.props.loading : false}
                            scroll={{ y: '58vh', x: 1000 }}
                            columns={this.columns}
                            dataSource={this.state.display ? this.state.display : []}
                        />
                    </div>
                    <div type="flex" justify="end">
                        <Col style={{ display: 'block' }}>
                            <Pagination
                                onChange={page => this.onChangePage(page)}
                                total={this.state.total}
                                pageSize={this.state.limit}
                                defaultCurrent={this.state.page}
                                current={this.state.page}
                                className="pagination"
                                style={{ position: 'unset', textAlign: 'right' }}
                            />
                        </Col>
                    </div>
                </div>
            </LocaleProvider>
        )
    }
}



// function mapDispatchToProps(dispatch) {
//     return {
//         // setContentCustomTable: (rows, category) => dispatch(setContentCustomTable(rows, category)),
//     }
// }



export default CustomTable
