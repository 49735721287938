import { get } from '../utils'
import { bahttext } from 'bahttext'

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('./vfs_fonts.js');
pdfMake.vfs = pdfFonts.vfs;

const getImageDataUrl = (imagePath) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      if (xhr.status === 200) {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      } else {
        console.error(`Image not found at ${imagePath}`);
        resolve(null);
      }
    };
    xhr.onerror = function() {
      console.error(`Failed to load image at ${imagePath}`);
      resolve(null);
    };
    xhr.open('GET', imagePath);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

const getHeader = (companyDetail, msgheader, logo) => {
  return {
    columns: [
      {
        width: '50%',
        margin: [40, 50, 0, 0],
        stack: [
          ...logo? [{ 
            width: 100, 
            image: logo, 
            fit: [100, 100] 
          }]
          :
          []
        ]
      },
      {
        width: '50%',
        margin: [0, 50, 40, 0],
        stack: [
          { text: msgheader, bold: true, fontSize: 20, alignment: 'right' },
          { text: companyDetail.title, bold: true, fontSize: 14, alignment: 'right' },
          { text: 'TAX ID: ' + `${companyDetail.tax ? companyDetail.tax : ""}`, bold: true, fontSize: 14, alignment: 'right' },
          { text: companyDetail.address, fontSize: 12, alignment: 'right' },
          { text: companyDetail.email, fontSize: 12, alignment: 'right' },
          { text: companyDetail.tel, fontSize: 12, alignment: 'right' },
        ]
      }
    ]
  }
};


const getStudentSection = (orderDetail, type) => {
  let content = {}
  let customerAddressObj = {
    customer_name: "",
    customer_address: "",
    customer_telephone: ""
  }
  if (type == 'invoice') {
    customerAddressObj = {
      customer_name: orderDetail.customer_name,
      customer_address: orderDetail.customer_address,
      customer_telephone: orderDetail.customer_telephone
    }
    content = {
      width: '50%',
      margin: [0, 10, 40, 20],
      stack: [
        { text: "ใบแจ้งหนี้เลขที่" + ' : ' + orderDetail.order_number, bold: true, fontSize: 14, alignment: 'right' },
        { text: "วันที่ใบแจ้งหนี้" + ' : ' + orderDetail.create_datetime.slice(0, 10), bold: true, fontSize: 14, alignment: 'right' },
        // { text: "จำนวนเงิน(บาท)" + ' : ' + (orderDetail.total_price).toString(), bold: true, fontSize: 14, alignment: 'right' }
      ]
    }
  } else if (type == 'receipt') {
    customerAddressObj = {
      customer_name: orderDetail.customer_delivery_name,
      customer_address: orderDetail.customer_delivery_address,
      customer_telephone: orderDetail.customer_delivery_telephone
    }
    content = {
      width: '50%',
      margin: [0, 10, 40, 20],
      stack: [
        { text: "ใบเสร็จเลขที่" + ' : ' + orderDetail.order_number, bold: true, fontSize: 14, alignment: 'right' },
        { text: "คำสั่งซื้อเลขที่" + ' : ' + orderDetail.order_number, bold: true, fontSize: 14, alignment: 'right' },
        { text: "วันที่ใบแจ้งหนี้" + ' : ' + orderDetail.create_datetime.slice(0, 10), bold: true, fontSize: 14, alignment: 'right' },
        // { text: "จำนวนเงิน(บาท)" + ' : ' + (orderDetail.total_price).toString(), bold: true, fontSize: 14, alignment: 'right' }
      ]
    }
  }
  return {
    columns: [
      {
        width: '50%',
        margin: [40, 10, 0, 20],
        stack: [
          { text: "แจ้งถึง", bold: true, fontSize: 16 },
          { text: customerAddressObj.customer_name? customerAddressObj.customer_name : '', bold: true, fontSize: 14 },
          { text: customerAddressObj.customer_address ? customerAddressObj.customer_address : '', fontSize: 12 },
          { text: customerAddressObj.customer_telephone ? customerAddressObj.customer_telephone : '', fontSize: 12 }
        ]
      },
      content
    ]
  }
};

const getContent = (orderProductList) => {
  let content = orderProductList.map((item, index) => {
    let temp = []
    temp.push({ text: index + 1, alignment: 'center' })
    temp.push({ text: item.product_name, alignment: 'left' })
    temp.push({ text: item.price, alignment: 'center' })
    temp.push({ text: item.quantity, alignment: 'center' })
    temp.push({ text: item.quantity * item.price, alignment: 'center' })
    return temp
  })
  return {
    margin: [40, 0, 40, 0],
    table: {
      widths: ['10%', '45%', '15%', '15%', '15%'],
      body: [
        [
          { text: "No.", alignment: 'center' },
          { text: "Name", alignment: 'center' },
          { text: "Price", alignment: 'center' },
          { text: "Quantity", alignment: 'center' },
          { text: "Tatal Price", alignment: 'center' }
        ],
        ...content
      ]
    }
  }
}

const getCalculatorSection = (orderDetail, type) => {
  let paymentCheck_bankAccount = false
  let paymentCheck_credit = false
  let transferDetail = ''
  if(["receipt"].includes(type)){
    switch(orderDetail.payment_method_type){
      case "bank_transfer":
        let bankIdSubfix = orderDetail.bank_account_id ? orderDetail.bank_account_id.slice(-4) : ""
        transferDetail = bankIdSubfix ? `xxxxxx${bankIdSubfix} ${orderDetail.bank_code}` : ""
        paymentCheck_bankAccount = true
        break;
      default:
        paymentCheck_credit = true
        break;
    }
  }

  let content = {}
  if (type == 'invoice') {
    content = {
      width: '50%',
      margin: [40, 20, 0, 0],
      stack: []
    }
  } else if (type == 'receipt') {
    
    content = {
      width: '50%',
      margin: [40, 35, 0, 0],
      stack: [
        { text: '', fontSize: 14, alignment: 'center' },
        { text: '..................................................', fontSize: 14, alignment: 'center' },
        { text: "ลงชื่อผู้รับเงิน", bold: true, fontSize: 14, alignment: 'center' },
        { text: '('+bahttext(parseFloat(orderDetail.total_price))+')', fontSize: 14, alignment: 'center', marginBottom:40 },
        { text: ' ', fontSize: 14, alignment: 'center' },
        { text: "ช่องทางการชำระเงิน", fontSize: 12, alignment: 'left' },
        
        // { text: ' ' + "เงินสด", fontSize: 10, alignment: 'left' },
        // { text: ' ' + "โอนเงิน" + '........................................', fontSize: 10, alignment: 'left' },
        {
          text : [
            {text : paymentCheck_bankAccount ? '\u2611\ufe0f ' : '\u2610 ' ,font: 'openSansEmoji', fontSize: 12 },
              `โอนเงิน ......${paymentCheck_bankAccount ? transferDetail : '.......................'}......`,
          ]
        },
        {
          text : [
            {text : paymentCheck_credit ? '\u2611\ufe0f ' : '\u2610 ' ,font: 'openSansEmoji', fontSize: 12 },
              `บัตรเครดิต`,
          ]
        },

        // { text: ' ' + " บัตรเครดิต", fontSize: 10, alignment: 'left' },
        // { text: ' ' + "บัญชีธนาคารออนไลน์ Paypal", fontSize: 10, alignment: 'left' },
        { text: "หมายเหตู" + ' : ..............................................................................', fontSize: 10, alignment: 'left' }
      ]
    }
  }
  return {
    columns: [
      content,
      {
        width: '28%',
        margin: [0, 20, 0, 0],
        stack: [
          { text: "ยอดรวม" + ' : ', bold: true, fontSize: 14, alignment: 'right' },
          { text: "ส่วนลด" + ' : ', bold: true, fontSize: 14, alignment: 'right' },
          { text: "ยอดรวมสุทธิ" + ' : ', bold: true, fontSize: 14, alignment: 'right' }
        ]
      },
      {
        width: '22%',
        margin: [0, 20, 40, 0],
        stack: [
          { text: orderDetail.price.toFixed(2) + " บาท", bold: true, fontSize: 14, alignment: 'right' },
          { text: orderDetail.discount_price.toFixed(2) + " บาท", bold: true, fontSize: 14, alignment: 'right' },
          { text: (orderDetail.total_price).toFixed(2).toString() + " บาท", bold: true, fontSize: 14, alignment: 'right' }
        ]
      }
    ]
  }
};

const getInvoiceCalculatorSection = (student) => {
  const type = 'invoice'
  return getCalculatorSection(student, type)
};

const getReceiptCalculatorSection = (orderDetail) => {
  const type = 'receipt'
  return getCalculatorSection(orderDetail, type)
};

const getInvoicePDF = (orderDetail, orderProductList, logo, companyDetail) => {
  const typeInvoice = 'invoice'
  const msgInvoiceHeader = 'Invoice'
  let cc = []
  var totalRow = 8;
  var totalCourse = orderProductList.length
  for (var i = 0; i < totalCourse; i += totalRow) {
    let orderProductPerPage = orderProductList.slice(i, i + totalRow);
    let calculatorSection = { text: '' }
    if (totalCourse - i <= totalRow) {
      calculatorSection = getInvoiceCalculatorSection(orderDetail)
    }
    cc.push(
      [
        getHeader(companyDetail, msgInvoiceHeader, logo),
        getStudentSection(orderDetail, typeInvoice),
        getContent(orderProductPerPage),
        calculatorSection,
        { text: '', pageBreak: totalCourse - i <= totalRow ? '' : 'after' }
      ]
    )
  }
  const docDefinition = {
    content: [
      ...cc
    ],
    defaultStyle: {
      font: 'THSarabunNew',
      padding: 0,
      margin: 0,
      fontSize: 10,
    },
    styles: {

    },
    pageMargins: [20, 20, 20, 20],
  };
  return pdfMake.createPdf(docDefinition)
};

const getReceiptPDF = (orderDetail, orderProductList, logo, companyDetail) => {
  const typeReceipt = 'receipt'
  const msgReceiptHeader = 'Receipt'
  let cc = []
  var totalRow = 8;
  var lengthOrderProductList = orderProductList.length
  for (var i = 0; i < lengthOrderProductList; i += totalRow) {
    let orderProductPerPage = orderProductList.slice(i, i + totalRow);
    let calculatorSection = { text: '' }
    if (lengthOrderProductList - i <= totalRow) {
      calculatorSection = getReceiptCalculatorSection(orderDetail)
    }
    cc.push(
      [
        getHeader(companyDetail, msgReceiptHeader, logo),
        getStudentSection(orderDetail, typeReceipt),
        getContent(orderProductPerPage),
        calculatorSection,
        { text: '', pageBreak: lengthOrderProductList - i <= totalRow ? '' : 'after' }
      ]
    )
  }
  const docDefinition = {
    content: [
      ...cc
    ],
    defaultStyle: {
      font: 'THSarabunNew',
      padding: 0,
      margin: 0,
      fontSize: 10,
    },
    styles: {

    },
    pageMargins: [20, 20, 20, 20],
  };
  return pdfMake.createPdf(docDefinition)
};

const transform = async (doc, action) => {
  switch (action) {
    case 'print': {
      return doc.print()
    }
    case 'download': {
      return doc.download()
    }
    case 'base64': {
      const base64 = await new Promise(resolve => doc.getBase64(data => resolve(data)))
      return base64
    }
    case 'blob': {
      const base64 = await new Promise(resolve => doc.getBase64(data => resolve(data)))
      const blob = b64toBlob(base64, 'application/pdf');
      return blob
    }
    default: return doc.open()
  }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const callOderReceiptGeneralPDF = async (orderId) => {
  try {
    const orderDetail = await get('/print/crm/administrator/sales/order/' + orderId)
    const orderProductList = await get('/print/crm/administrator/sales/order/' + orderId + '/product')
    const setting = await get('/api/setting')
    const companyDetail = {
      title: setting.customer_name,
      tax: setting.customer_tax,
      address: setting.customer_address,
      email: setting.customer_email,
      tel: setting.customer_tel,
    }
    const logo = await getImageDataUrl(setting.customer_doc_logo_url);
    const doc = getReceiptPDF(orderDetail, orderProductList, logo, companyDetail)
    const response = await transform(doc, 'print')
    return response
  } catch (error) {
    console.log('error :>> ', error);
    return error
  }
  
}

export const callOderInvoiceGeneralPDF = async (orderId) => {
  try {
    const orderDetail = await get('/print/crm/administrator/sales/order/' + orderId)
    const orderProductList = await get('/print/crm/administrator/sales/order/' + orderId + '/product')
    const setting = await get('/api/setting')
    const companyDetail = {
      title: setting.customer_name,
      tax: setting.customer_tax,
      address: setting.customer_address,
      email: setting.customer_email,
      tel: setting.customer_tel,
    }
    const logo = await getImageDataUrl(setting.customer_doc_logo_url);
    const doc = getInvoicePDF(orderDetail, orderProductList, logo, companyDetail)
    const response = await transform(doc, 'print')
    return response
  } catch (error) {
    console.log('error :>> ', error);
    return error
  }
}


export const callOderReceiptPDF = (orderId) => {
  return callOderReceiptGeneralPDF(orderId);
}

export const callOderInvoicePDF = (orderId) => {
  return callOderInvoiceGeneralPDF(orderId);
}

